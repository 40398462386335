<template>
  <div class="password-input">
    <div class="password-input__wrap">
      <label class="visually-hidden" :for="name">
        {{ label }}
      </label>
      <input
        class="form-control"
        :type="passwordType"
        :name="name"
        :id="name"
        :placeholder="label"
        v-model="password"
        @keyup="onPasswordChange"
      >
      <button class="password-input__toggle btn-reset" type="button" @click="togglePasswordType">
        <img src="../assets/icon-eye.svg" alt="" aria-hidden="true">
        <span class="visually-hidden">Wachtwoord tonen/verbergen</span>
      </button>
    </div>

    <div class="password-input__strength" :style="{ color: colors[strengthLevel] }">
      <div class="password-input__strength-line">
        <div
          v-for="index in [0, 1, 2, 3, 4]"
          :key="index"
          :class="{ 'is-active': strengthLevel >= index }"
        >
        </div>
      </div>

      <div class="password-input__strength-text">
        {{ password.length ? messages[strengthLevel] : '&nbsp;' }}
      </div>
    </div>
  </div>
</template>

<script>
import mostUsedPasswords from '../helpers/mostUsedPasswords';

export default {
  props: {
    name: {
      type: String,
      default: 'password',
    },

    label: {
      type: String,
    },
  },

  data() {
    return {
      password: '',
      passwordType: 'password',
      strengthLevel: 0,
      messages: [
        'Zwak wachtwoord',
        'Matig wachtwoord',
        'Prima wachtwoord',
        'Sterk wachtwoord',
        'Heel sterk wachtwoord',
      ],
      colors: [
        '#f06b00',
        '#fa9f57',
        '#afe064',
        '#9ed540',
        '#9ac40d',
      ],
    };
  },

  methods: {
    togglePasswordType() {
      this.passwordType = this.passwordType === 'password' ? 'text' : 'password';
    },

    onPasswordChange(event) {
      const { value } = event.currentTarget;
      const specialCharacters = value.match(/[@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/g) || [];
      const capitals = value.match(/[A-Z]/) || [];
      // const numbers = value.match(/[0-9]/);

      if (typeof value === 'undefined') {
        this.strengthLevel = 0;
        return;
      }

      this.$emit('change', event, value);

      if (mostUsedPasswords.indexOf(value) >= 0) {
        this.strengthLevel = 0;
        return;
      }

      if (value.length >= 14) {
        this.strengthLevel = 4;
        return;
      }

      if (value.length < 6) {
        this.strengthLevel = 0;
        return;
      }

      if (value.length < 8) {
        this.strengthLevel = 1;
      }

      if (value.length >= 8 && specialCharacters.length >= 4) {
        this.strengthLevel = 4;
        return;
      }

      if (value.length >= 8 && specialCharacters.length && capitals.length >= 2) {
        this.strengthLevel = 4;
        return;
      }

      if (value.length >= 8 && specialCharacters.length && capitals.length) {
        this.strengthLevel = 3;
        return;
      }

      this.strengthLevel = 2;
    },
  },
};
</script>

<style>
.password-input__wrap {
  position: relative;
  margin-bottom: 1.25rem;
}

.password-input__toggle {
  position: absolute;
  top: 0;
  right: .5rem;
  width: 2.375rem;
  height: 2.375rem;
}

.password-input__strength-text {
  margin-top: .5rem;
  text-align: center;
  font-weight: 500;
}

.password-input__strength-line {
  display: flex;
  justify-content: space-between;
}

.password-input__strength-line > div {
  height: 4px;
  border-radius: 2px;
  background-color: #ddd;
  width: 18%;
  flex: 0 0 18%;
}

.password-input__strength-line > div.is-active {
  background-color: currentColor;
}
</style>
