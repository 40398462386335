<template>
  <div>
    <head-bar title="Wachtwoord wijzigen">
      <template v-slot:left>
        <head-bar-link @click="onBackClick">
          <img src="../../assets/arrow-left.svg" alt="" aria-hidden="true" width="16" height="12" />
          <span class="visually-hidden">Terug</span>
        </head-bar-link>
      </template>
    </head-bar>

    <app-layout classes="password">
      <div class="p-2 p-md-3">
        <h1 class="password__heading mb-3">
          Vul je nieuwe wachtwoord in
        </h1>

        <form action="" method="POST" @submit.prevent="onSubmit">
          <password-input
            class="mb-3"
            label="Vul je nieuwe wachtwoord in"
            @change="onPasswordChange"
          />

          <Button
            button-type="submit"
            classes="btn--full-width"
            :disabled="!canSubmit"
            :show-arrow="false"
          >
            Nieuw wachtwoord opslaan
          </Button>
        </form>
      </div>
    </app-layout>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { useToast } from 'vue-toastification';
import AppLayout from '../AppLayout.vue';
import HeadBar from '../../components/HeadBar.vue';
import HeadBarLink from '../../components/HeadBarLink.vue';
import PasswordInput from '../../components/PasswordInput.vue';
import Button from '../../components/Button.vue';

export default {
  setup() {
    const toast = useToast();
    return { toast };
  },

  components: {
    Button,
    PasswordInput,
    HeadBarLink,
    HeadBar,
    AppLayout,
  },

  data() {
    return {
      canSubmit: false,
    };
  },

  computed: {
    ...mapGetters([
      'user',
    ]),
  },

  methods: {
    onSubmit(event) {
      const formData = new FormData(event.currentTarget);
      const password = formData.get('password');

      this.$store.dispatch('updatePassword', password)
        .then(() => {
          this.toast.success('Wachtwoord gewijzigd', {
            timeout: 2500,
          });
        });
    },

    onPasswordChange(event, value) {
      this.canSubmit = value.length >= 6;
    },

    onBackClick() {
      this.$router.go(-1);
    },
  },
};
</script>

<style>
.app-layout.password {
  background-color: #fff;
  padding-top: 3.5rem;
}

.password__heading {
  font-size: 1.25rem;
  text-align: center;
}
</style>
